//
//_logo.scss
//
.avatar-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .avatar-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* For company logos: fixed max-height container */
  .logo-container {
    max-width: 250px;
    height: 150px;
    @media only screen and (max-width: 768px) {
        width: 100%;
        min-height: 80px;
        max-height: 100px;
      }
  }
  
  .logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }