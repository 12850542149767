.ck-color-table > div:nth-child(2){
    height: 250px;
    overflow: scroll;
}
.ck-table-form > .ck-dropdown__panel > .ck-color-grid {    
    height: 250px;
    overflow: scroll;
}
.ck-dropdown__panel_sw > div:nth-child(2) {
    height: 250px;
    overflow: scroll;
}
.fixedtop {
    position: fixed;
    top: 0;
}
.ck-dropdown__panel {
    max-height: 220px !important;
    overflow-y: auto;
}